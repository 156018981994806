import React from 'react'
import { Flex, Text } from '../primitives'
import { Link } from 'gatsby'
import { Image } from '../shareable/Images/Image'

export const BlogItem = ({ title, src, url }) => {
  return (
    <Flex width={['100%', '100%', '33%']} mb={2}>
      <Link to={url} style={{ width: '100%' }}>
        <Flex
          pb={4}
          pr={[2, 2, 2]}
          pl={[2, 2, 2]}
          pt={0}
          style={{
            cursor: 'pointer',
            position: 'relative',
            borderRadius: '5px'
          }}
          width={'100%'}
          flexDirection={'column'}>
          <Image
            style={{
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px'
            }}
            height={'320px'}
            width={'100%'}
            src={src}
          />

          <Flex
            bg={'primaryLight'}
            mr={[2, 2, 2]}
            ml={[2, 2, 2]}
            style={{
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              position: 'absolute',
              bottom: 0,
              right: 0,
              left: 0
            }}
            jusifyContent={'center'}
            alignItems={'center'}
            height={'75px'}>
            <Text p={4}>{title}</Text>
          </Flex>
        </Flex>
      </Link>
    </Flex>
  )
}
