import React, { useEffect, useState } from 'react'
import Layout from '../components/layouts'
import { Flex, Text } from '../components/primitives'
import { BlogItem } from '../components/blog/BlogItem'
import { graphql } from 'gatsby'
import { Footer } from '../components/shareable/footer/Footer'
import { BlogMenu } from '../components/shareable/navbar/BlogMenu'
import { HeadingMedium } from '../components/shareable/text/HeadingMedium'

export const GatsbyQuery = graphql`
  {
    hemsetAPI {
      blogs {
        _id
        createdAt
        updatedAt
        title
        description
        url
        imageFile {
          childImageSharp {
            fluid(maxWidth: 370) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        category
        files {
          origName
          name
          url
          uploadUrl
        }
      }
    }
  }
`

const sortBlog = ({ blogs, hash }) => {
  if (!blogs) {
    return []
  }
  if (!hash) {
    return blogs
  }
  return blogs.filter((b) => b.category === hash)
}
export default ({ data, navigate, location }) => {
  const [hash, setHash] = useState(null)
  const {
    hemsetAPI: { blogs }
  } = data
  const [modalIsOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (location.hash) {
      setHash(location.hash.replace('#', ''))
    }
  }, [location.hash])

  console.log(hash)
  return (
    <Layout
      blackNav={true}
      modelOpenProps={modalIsOpen}
      onCloseModal={() => {
        setIsModalOpen(false)
      }}
      headTitle={'Stories & inspiration | Hemset.se'}
      metaDesc={
        'Låt dig inspireras av inredarnas smarta inredningstips och annan läsning från oss på Hemset - inredningshjälp online.'
      }
     >
      <Flex minHeight={'100vh'} width={'100%'}>
        {/*        <HeadingMedium mt={'100px'} mb={10} title={'Blogg'} />*/}

        <Flex
          width={'100%'}
          mt={['60px', '60px', '80px']}
          jusifyContent={'center'}
          flexDirection={['column', 'column', 'row']}>
          <Flex
            width={['100%', '100%', '250px']}
            flexDirection={'column'}
            alignItems={'center'}>
            <HeadingMedium mb={6} title={'Blogg'} />
            <Text textAlign={'center'} pl={2} pr={2} mb={6}>
              Här kan du få inspiration, läsa om våra kunder, inredare och vår
              tjänst. Klicka på en kategori för att avgränsa blogginläggen.
            </Text>
            <BlogMenu />
          </Flex>
          <Flex
            mb={'50px'}
            flexDirection={'column'}
            maxWidth={'1000px'}
            width={['100%', '100%', '80%']}>
            <Flex flexWrap={'wrap'}>
              {sortBlog({ blogs: blogs, hash: hash }).map((item) => {
                return (
                  <BlogItem
                    url={item.url}
                    key={item._id}
                    title={item.title}
                    src={
                      item.imageFile ? item.imageFile.childImageSharp.fluid : ''
                    }
                  />
                )
              })}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Footer
        actionClick={() => {
          setIsModalOpen(true)
        }}
      />
    </Layout>
  )
}
