import React from 'react'
import Img from 'gatsby-image'

export const Image = ({
  width = '100%',
  height = '100%',
  src,
  style,
  objectFit = 'cover'
}) => {
  return (
    <Img
      style={{ width: width, height: height, objectFit: objectFit, ...style }}
      imgStyle={{ objectFit: objectFit }}
      fluid={src}/>
  )
}
